<template>
  <label v-if="readonly" class="col-form-label">
    <slot name="value" v-bind="{ innerValue }"><p>{{innerValue}}</p></slot>
  </label>
  <ValidationProvider v-else :vid="vid" :name="name" :rules="rules" immediate>
    <b-form-group slot-scope="{ valid, invalid, validated, errors }" v-bind="$attrs" :label="label">
      <b-form-textarea
        v-model="innerValue"
        :placeholder="placeholder"
        :name="name"
        :state="(validated && invalid) ? ((errors[0]) ? false : (valid ? true : null)) : null"
        @input="$emit('change', $event)"
        :rows="rows"
        :max-rows="maxRows"
        :no-auto-shrink="noAutoShrink"
      ></b-form-textarea>
      <b-form-invalid-feedback><span v-for="error in errors">{{error}}</span></b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import BaseInput from './base.vue'

export default {
  inheritAttrs: false,
  extends: BaseInput,
  props: {
    placeholder: {
      type: String
    },
    rows: {
      type: Number|String,
      default: 3
    },
    maxRows: {
      type: Number|String,
      default: 8
    },
    noAutoShrink: {
      type: Boolean,
      default: true
    },
    rules: {
      type: [Object, String],
      default: ''
    },
  }
}
</script>
